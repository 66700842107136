import axios from 'axios'
import Store from '../../store'
import {
  errorHandler,
  generateNoOptionsQuery,
  successHandler
} from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/airtime`

export default {
  airtime() {
    return {
      recharge: async ({ amount, network, number, otp }) => {
        const endpoint = `${URL}`
        const data = { amount, network, number, otp }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getProviders: async (params = {}) => {
        const endpoint = `${URL}/providers`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getAll: async ({ populate = [], userId }) => {
        const endpoint = `${URL}${generateNoOptionsQuery(
          populate
        )}&user=${userId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
