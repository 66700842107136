import axios from 'axios'
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}`

export default {
  data() {
    return {
      getProviders: async () => {
        const endpoint = `${URL}/dataplan/providers`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getProviderData: async (params = {}) => {
        const endpoint = `${URL}/dataplans`

        try {
          const res = await axios.post(endpoint, {
            params,
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
