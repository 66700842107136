<template>
  <v-container>
    <v-btn text color="accent" @click="$router.go(-1)" class="mb-2">
      <v-icon left> mdi-arrow-left-thick </v-icon>
      Back
    </v-btn>
    <div v-if="user.country === 'Ghana'">
      Sorry, Airtime and Data purchase is not Available for Ghana users yet.
    </div>
    <div v-else>
      <h2 class="mb-4">Purchase Airtime</h2>
      <p class="text-subtitle-1 font-weight-bold">
        Got questions? Call or Whatsapp {{ siteData.phone }}
      </p>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" md="6">
              <v-card flat rounded="md">
                <v-form ref="rechargeForm" @submit.prevent="validate">
                  <v-container>
                    <v-card-text>
                      <v-card color="primary white--text" class="mb-4">
                        <v-card-title class="py-2 mb-0">Wallet</v-card-title>
                        <v-card-text class="white--text">
                          <p class="overline mb-0">BALANCE</p>
                          <p class="text-h6 mb-0">
                            NGN {{ wallet.balance || 0 }}
                          </p>
                        </v-card-text>
                      </v-card>
                      <v-tabs v-model="tabs">
                        <v-tab>Airtime</v-tab>
                        <v-tab>Data</v-tab>
                      </v-tabs>
                      <v-tabs-items class="mt-5" v-model="tabs">
                        <!-- Airtime -->
                        <v-tab-item>
                          <div v-if="siteData.airtimeFeatureAvailable">
                            <v-select
                              @change="selectProvider"
                              :items="providers"
                              :loading="providers.length === 0"
                              filled
                              item-text="name"
                              item-value="_id"
                              v-model="airtime.network"
                              label="Select a Provider"
                              :rules="[rules.required]"
                              :disabled="recharging"
                            ></v-select>
                            <v-text-field
                              :disabled="recharging"
                              :rules="[rules.required]"
                              v-model="airtime.phone"
                              filled
                              label="Phone Number"
                            ></v-text-field>
                            <p>Select Airtime Amount</p>
                            <v-item-group
                              @change="pickedAirtime"
                              v-model="selectedAmount"
                            >
                              <v-row>
                                <v-col
                                  v-for="amount in selectedProviderData.amounts"
                                  :key="amount"
                                  cols="4"
                                  md="3"
                                >
                                  <v-item
                                    :value="amount"
                                    v-slot="{ active, toggle }"
                                  >
                                    <v-card
                                      :color="active ? 'primary' : ''"
                                      class="d-flex align-center"
                                      height="45"
                                      @click="toggle"
                                    >
                                      <v-scroll-y-transition>
                                        <div
                                          :class="`flex-grow-1 subtitle-2 text-center ${
                                            active ? 'white--text' : ''
                                          }`"
                                        >
                                          {{ amount }}
                                        </div>
                                      </v-scroll-y-transition>
                                    </v-card>
                                  </v-item>
                                </v-col>
                                <v-col cols="5" md="5">
                                  <v-text-field
                                    dense
                                    type="number"
                                    :disabled="recharging || !airtime.network"
                                    v-model="amountToBuyTyped"
                                    suffix="NGN"
                                    filled
                                    label="Other"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-item-group>
                            <v-text-field
                              :disabled="recharging"
                              v-model="airtime.otp"
                              :rules="[rules.required]"
                              filled
                              label="OTP"
                              hint="An OTP  will be sent to your registered email to approve this transaction"
                              persistent-hint
                            >
                              <template v-slot:append-outer>
                                <v-btn
                                  :loading="sendingOtp"
                                  @click="generateOtp('airtime')"
                                  color="primary"
                                  dark
                                >
                                  Request OTP
                                </v-btn>
                              </template>
                            </v-text-field>

                            <p
                              class="error--text"
                              v-if="
                                !canBuy && (amountToBuyTyped || amountToBuy)
                              "
                            >
                              You don't have enough funds for this transaction
                              <br />
                              <v-btn
                                to="/user/wallet/deposit"
                                small
                                depressed
                                color="accent"
                                >FUND WALLET</v-btn
                              >
                            </p>
                            <v-btn
                              type="submit"
                              class="mt-4"
                              :disabled="!canBuy"
                              :loading="recharging"
                              large
                              color="primary"
                              block
                              >Recharge</v-btn
                            >
                          </div>
                          <div v-else>
                            {{ siteData.airtimeFeatureMessage }}
                          </div>
                        </v-tab-item>
                        <!-- Data -->
                        <v-tab-item>
                          <div v-if="siteData.dataFeatureAvailable">
                            <v-select
                              @change="selectDataProvider"
                              :items="['MTN', 'GLO', '9MOBILE', 'AIRTEL']"
                              :loading="dataAvailable.length === 0"
                              filled
                              v-model="dataPlan.network"
                              label="Select a Carrier Network"
                              :rules="[rules.required]"
                              :disabled="rechargingData"
                            ></v-select>
                            <v-text-field
                              :disabled="rechargingData"
                              :rules="[rules.required]"
                              v-model="dataPlan.phone"
                              filled
                              label="Phone Number"
                            ></v-text-field>
                            <v-autocomplete
                              filled
                              :disabled="selectedDataProviderData.length == 0"
                              :items="selectedDataProviderData"
                              item-text="product_name"
                              item-value="product_id"
                              label="Select Data Amount"
                              :rules="[rules.required]"
                              v-model="dataPlan.name"
                            >
                            </v-autocomplete>
                            <v-text-field
                              :disabled="recharging"
                              v-model="dataPlan.otp"
                              :rules="[rules.required]"
                              filled
                              label="OTP"
                              hint="An OTP  will be sent to your registered email to approve this transaction"
                              persistent-hint
                            >
                              <template v-slot:append-outer>
                                <v-btn
                                  :loading="sendingOtp"
                                  @click="generateOtp('data')"
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Request OTP
                                </v-btn>
                              </template>
                            </v-text-field>
                            <p
                              class="error--text"
                              v-if="!canBuyData && dataAmountAndCode.amount"
                            >
                              You don't have enough funds for this transaction
                              <br />
                              <v-btn
                                to="/user/wallet/deposit"
                                small
                                depressed
                                color="accent"
                                >FUND WALLET</v-btn
                              >
                            </p>
                            <v-btn
                              type="submit"
                              class="mt-4"
                              :disabled="!canBuyData"
                              :loading="rechargingData"
                              large
                              @click="buyData"
                              color="primary"
                              block
                              >continue</v-btn
                            >
                          </div>
                          <div v-else>
                            {{ siteData.dataFeatureMessage }}
                          </div>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card-text>
                  </v-container>
                </v-form>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-card
                    rounded="md"
                    flat
                    class="animate__animated animate__zoomIn"
                  >
                    <v-container>
                      <v-card
                        color="light-blue"
                        flat
                        rounded="md"
                        class="lighten-4 my-2 px-3"
                      >
                        <v-card-text class="pa-2">
                          <v-row class="py-4" justify="space-between">
                            <span
                              class="text-left text-left black--text subtitle-2"
                              >YOU ARE BUYING</span
                            >
                            <span class="text-right black--text subtitle-2"
                              >NGN
                              {{
                                tabs == 0
                                  ? amountToBuy || amountToBuyTyped
                                  : `${dataPlan.network || ''}`
                              }}
                              {{ tabs == 0 ? 'Airtime' : 'Data' }}</span
                            >
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card
                        color="light-blue"
                        flat
                        rounded="md"
                        class="lighten-4 my-2 px-3 py-3"
                      >
                        <v-card-text class="pa-2">
                          <v-row justify="space-between" align="center">
                            <span class="text-left black--text subtitle-2"
                              >YOU ARE PAYING</span
                            >
                            <div
                              class="text-right black--text subtitle-2 d-flex align-center"
                            >
                              <span class="d-inline mr-2"
                                >NGN
                                {{
                                  tabs == 0
                                    ? numberWithCommas(
                                        amountToBuy
                                          ? Number(amountToBuy) +
                                              Number(amountToBuy) *
                                                (airtimeRate / 100)
                                          : Number(amountToBuyTyped) +
                                              Number(amountToBuyTyped) *
                                                (airtimeRate / 100)
                                      )
                                    : numberWithCommas(
                                        Number(dataAmountAndCode.amount) +
                                          Number(dataAmountAndCode.amount) *
                                            (dataRate / 100)
                                      )
                                }}</span
                              >
                              <span class="d-inline">
                                <p
                                  style="font-size: 12px"
                                  class="mb-0 text-decoration-line-through font-weight-light grey--text"
                                >
                                  {{ tabs == 0 ? 'NGN' : '' }}
                                  {{
                                    tabs == 0
                                      ? amountToBuy || amountToBuyTyped
                                      : ''
                                  }}
                                </p>
                                <p
                                  style="font-size: 12px"
                                  class="mb-0 font-weight-light grey--text"
                                >
                                  {{
                                    tabs == 0
                                      ? (airtimeRate > 0
                                          ? airtimeRate
                                          : -airtimeRate) || 0
                                      : 0
                                  }}% off
                                </p>
                              </span>
                            </div>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-container>
                    <v-container>
                      <v-divider class="py-3"></v-divider>
                      <div>
                        <p
                          style="font-size: 14px"
                          class="mb-0 font-weight-regular"
                        >
                          Payment Method
                        </p>
                        <p class="primary--text text-h6">Naira, from Wallet</p>
                      </div>
                      <div>
                        <p
                          style="font-size: 14px"
                          class="mb-0 font-weight-regular"
                        >
                          Available
                        </p>
                        <p class="primary--text text-h6">Instantly</p>
                      </div>
                      <div>
                        <p
                          style="font-size: 14px"
                          class="mb-0 font-weight-regular"
                        >
                          Purchasing for
                        </p>
                        <p class="primary--text text-h6">{{ airtime.phone }}</p>
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dataApi from '../../../api/systemData'
import rules from '../../../utils/rules'
import airtimeApi from '../../../api/users/airtime'
import dataPlansApi from '../../../api/users/dataPlan'
import { numberWithCommas } from '../../../utils/helpers'
import misc from '../../../api/users/misc'
// import { updateProfile } from '../../../utils/helpers'

export default {
  data() {
    return {
      numberWithCommas,
      rules,
      providers: [],
      dataAvailable: [],
      dataPlan: {},
      rechargingData: false,
      usePin: false,
      selectedProviderData: {
        amounts: []
      },
      selectedDataProviderData: [],
      tabs: 0,
      airtime: {},
      amountToBuy: '',
      amountToBuyTyped: '',
      recharges: [],
      recharging: false,
      selectedAmount: 0,
      airtimeRate: '',
      dataRate: '',
      sendingOtp: false
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      wallet: (state) => state.user.wallet,
      siteData: (state) => state.user.siteSettings
    }),
    networkId() {
      return this.providers.find((p) => p.code === this.dataPlan.network)._id
    },
    dataAmountAndCode() {
      const data = this.selectedDataProviderData.find(
        (d) => d.product_id === this.dataPlan.name
      )
      return {
        amount: data ? Number(data.official_price) : 0,
        id: data ? data.product_id : 0
      }
    },
    canBuy() {
      return (
        (this.amountToBuy <= (this.wallet.balance || 0) &&
          this.amountToBuy !== '') ||
        (this.amountToBuyTyped <= this.wallet.balance &&
          this.amountToBuyTyped !== '')
      )
    },
    canBuyData() {
      return (
        this.dataAmountAndCode.amount &&
        this.dataAmountAndCode.amount !== '' &&
        this.dataAmountAndCode.amount <= (this.wallet.balance || 0)
      )
    }
  },
  watch: {
    amountToBuyTyped(newValue, oldValue) {
      if (newValue.length > 0) {
        this.amountToBuy = ''
        this.selectedAmount = undefined
      }
    }
  },
  created() {
    this.airtime.phone = this.user.phone
    this.dataPlan.phone = this.user.phone
    this.getProviders()
    this.getAllAvailableDataPlans()
    this.getRates()
    this.$store.dispatch('user/getSiteSettings')
  },
  methods: {
    async getRates() {
      const res = await dataApi.data().siteSettings('user')
      this.airtimeRate = res.data.data.airtimeRate
      this.dataRate = res.data.data.dataRate
    },
    async buyData() {
      this.rechargingData = true
      const res = await dataPlansApi.data().buyDataPlan({
        planId: this.dataAmountAndCode.id,
        number: this.dataPlan.phone,
        otp: this.dataPlan.otp
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.rechargingData = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Data Recharge successful',
        status: true
      })

      // const user = await profileApi.profile().get()
      // updateProfile({ profile: user.data.data })

      this.rechargingData = false
      this.dataPlan.network = ''
      this.dataPlan.amount = ''
      this.dataPlan.pin = ''
      this.$router.push('/user/dashboard')
    },
    async getAllAvailableDataPlans() {
      const res = await dataPlansApi.data().getDataPlans()
      const plans = res.data.data

      const arrObj = {
        mtn: [],
        glo: [],
        '9mobile': [],
        airtel: []
      }

      // plans.forEach((el) => {
      //   if (el.product_id.toString().toLowerCase().includes('mtn')) {
      //     arrObj.mtn.push({ ID: el.product_id, PRODUCTS: [] })
      //   }
      //   if (el.product_id.toString().toLowerCase().includes('9mobile')) {
      //     arrObj['9mobile'].push({ ID: el.product_id, PRODUCTS: [] })
      //   }
      //   if (el.product_id.toString().toLowerCase().includes('glo')) {
      //     arrObj.glo.push({ ID: el.product_id, PRODUCTS: [] })
      //   }
      //   if (el.product_id.toString().toLowerCase().includes('airtel')) {
      //     arrObj.airtel.push({ ID: el.product_id, PRODUCTS: [] })
      //   }
      // })

      plans.forEach((el) => {
        if (el.product_id.toString().toLowerCase().includes('mtn')) {
          arrObj.mtn.push({
            ...el
          })
        }
        if (el.product_id.toString().toLowerCase().includes('9mobile')) {
          arrObj['9mobile'].push({
            ...el
          })
        }
        if (el.product_id.toString().toLowerCase().includes('glo')) {
          arrObj.glo.push({
            ...el
          })
        }
        if (el.product_id.toString().toLowerCase().includes('airtel')) {
          arrObj.airtel.push({
            ...el
          })
        }
      })

      // const arr = []
      // for (const key in plans) {
      //   if (Object.hasOwnProperty.call(plans, key)) {
      //     const element = plans[key]
      //     arr.push({
      //       name: key,
      //       product_id: element[0].ID,
      //       products: element[0].PRODUCT
      //     })
      //   }
      // }
      this.dataAvailable = arrObj
    },
    pickedAirtime(amount) {
      this.amountToBuyTyped = ''
      this.amountToBuy = amount
    },
    selectDataProvider(name) {
      this.selectedDataProviderData = this.dataAvailable[name.toLowerCase()]
    },
    selectProvider(id) {
      console.log(id)
      this.selectedProviderData = this.providers.find((d) => d._id === id)
    },
    validate() {
      if (this.$refs.rechargeForm.validate()) this.rechargeAirtime()
    },
    async getProviders() {
      const res = await dataApi
        .data()
        .getData('user', { type: 'mobilenetwork' })
      this.providers = res.data.data.data
    },

    async rechargeAirtime() {
      this.recharging = true
      const res = await airtimeApi.airtime().recharge({
        amount: this.amountToBuyTyped || this.amountToBuy,
        networkId: this.airtime.network,
        network: this.providers
          .find((p) => p._id === this.airtime.network)
          .name.toLowerCase(),
        number: this.airtime.phone,
        otp: this.airtime.otp
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.recharging = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Recharge successful',
        status: true
      })

      // const user = await profileApi.profile().get()
      // updateProfile({ profile: user.data.data })

      this.recharging = false
      this.airtime.network = ''
      this.airtime.amount = ''
      this.airtime.pin = ''
      this.amountToBuyTyped = ''
      this.$router.push('/user/dashboard')
    },
    async generateOtp(type) {
      this.sendingOtp = true
      const res = await misc.misc().generateOtp(type)
      if (res.error) {
        this.$store.dispatch('alert', {
          message: res.errorMessage.message || res.internalError.message,
          error: true,
          status: true
        })
        this.sendingOtp = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'OTP sent successfully, kindly check your email',
        status: true
      })
      this.sendingOtp = false
    }
  }
}
</script>

<style></style>
